import type { StatCardData } from '@emotioncod/cm-design-system/dist/types/types';
import type { CandidateState } from '~/stores/candidates/state';
import type { SortableCandidatesArr } from '~/types/bo-types';

/**
 * TODO
 * Add the label description on the StatCardData component in the Design System library
 * @param counts
 */
export function resolveCandidatesStats(
  counts: CandidateState['counts'],
  t: (key: string, ...args: any[]) => string
): (StatCardData & { statDescription?: string })[] {
  if (!counts) return [];

  const date = new Date();
  const options = { month: 'long', year: 'numeric' };
  // @ts-ignore
  const monthYear = date.toLocaleString('en-US', options);

  return [
    {
      statName: t('candidates.stats.totalCand'),
      iconName: 'mdiAccountGroup',
      statValue: counts.candidates,
      iconColor: 'purple'
    },
    {
      statName: t('candidates.stats.totalMatches'),
      statDescription: t('candidates.stats.data') + ` ${monthYear}`,
      iconName: 'mdiTargetAccount',
      statValue: counts.matches,
      iconColor: 'blue'
    },
    {
      statName: t('candidates.stats.matchesCand'),
      statDescription: t('candidates.stats.data') + ` ${monthYear}`,
      iconName: 'mdiBriefcaseAccount',
      statValue: counts.candidatesWithMatch,
      iconColor: 'red'
    },
    {
      statName: t('candidates.stats.totalOpportunities'),
      statDescription: t('candidates.stats.data') + ` ${monthYear}`,
      iconName: 'mdiArchive',
      statValue: counts.opportunities,
      iconColor: 'green'
    },
    {
      statName: t('candidates.stats.applicationsCand'),
      statDescription: t('candidates.stats.data') + ` ${monthYear}`,
      iconName: 'mdiBriefcaseCheck',
      statValue: counts.candidatesWithApplication,
      iconColor: 'light-blue'
    }
  ];
}

export function resolveCandidatesTableData(
  candidates: CandidateState['candidates'],
  t: (key: string, ...args: any[]) => string
): SortableCandidatesArr[] {
  return candidates.map((el) => ({
    ...el,
    fullName: el.name + ' ' + el.surname,
    openToWork: el.other?.stillLookingforJob
      ? t('actions.yes')
      : t('actions.no'),
    devTalentPartner:
      el.talentPartner?.name || t('candidates.table.toBeAssigned'),
    ral: el.job.salary.min.toString() || '--',
    jobRole: el.job.role,
    skills: el.job.skills.map(({ name }) => name).join(', ')
  }));
}
